import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;
const transUrl = process.env.REACT_APP_API_TRANS_URL;
const otherUrl = process.env.REACT_APP_API_OTHER_URL;

const initialState = {
    loading: false,
    chamas: [],
    members: [],
    statements: [],
    roles: [],
    invitedMembers: [],
    settlementAccounts: [],
    chamaGroupObj: {},
    newUserByIdObj: {},
    invitePendingApprovals: [],
    invitesRejected: [],
    notifications: [],
    chamaContributions: [],
    contributionTypeMembers: [],
    pivotData: [],
    chamaInviteRequests: [],
};

export const fetchChamas = createAsyncThunk('fetchSlice/fetchChamas', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/customers`);
    return res.data.data.result;
});

export const mpesaPush = createAsyncThunk('fetchSlice/mpesaPush', async (payObj) => {
    const res = await axiosInstance.get(`https://corporate.ndovupay.org/pngp/mpesaPush.action?phone=${payObj?.phone}&amount=${payObj?.amount}&accountref=${payObj?.accountref}`);
    return res.data;
});

export const fetchStatements = createAsyncThunk('fetchSlice/fetchStatements', async () => {
    const res = await axiosInstance.get(`${transUrl}/api/v2/trans`);
    return res.data.data;
});

export const fetchChamaRoles = createAsyncThunk('fetchSlice/fetchChamaRoles', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/role`);
    return res.data.data;
});

export const fetchInvitedmembers = createAsyncThunk('fetchSlice/fetchInvitedmembers', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/invite`);
    return res.data.data;
});

export const fetchSettlementAccounts = createAsyncThunk('fetchSlice/fetchSettlementAccounts', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/settlement`);
    return res.data.data;
});

export const fetchGroupMembers = createAsyncThunk('fetchSlice/fetchGroupMembers', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/member/chama`);
    return res.data.data;
});

export const fetchChamaGroupByCode = createAsyncThunk('fetchSlice/fetchChamaGroupByCode', async (code) => {
    const res = await axiosInstance.get(`${url}/api/v1/customers/fetchInvite/${code}`);
    return res.data.data;
});

export const fetchUserById = createAsyncThunk('fetchSlice/fetchUserById', async (id) => {
    const res = await axiosInstance.get(`${url}/api/v2/users/nationalId/${id}`);
    return res.data;
});

export const fetchPendingInvites = createAsyncThunk('fetchSlice/fetchPendingInvites', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/invite/status/PENDING`);
    return res.data.data;
});

export const fetchDeclinedInvites = createAsyncThunk('fetchSlice/fetchDeclinedInvites', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/invite/status/REJECT`);
    return res.data.data;
});

export const fetchNotifications = createAsyncThunk('fetchSlice/fetchNotifications', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/notification`);
    return res.data.data;
});

export const fetchChamaContributions = createAsyncThunk('fetchSlice/fetchChamaContributions', async (id) => {
    const res = await axiosInstance.get(`${url}/api/v1/debit-setup`);
    return res.data.data;
});

export const fetchContributionTypeMembers = createAsyncThunk('fetchSlice/fetchContributionTypeMembers', async (id) => {
    const res = await axiosInstance.get(`${url}/api/v1/debt-note/${id}`);
    return res.data.data;
});

export const fetchPivot = createAsyncThunk('fetchSlice/fetchPivot', async (id) => {
    const res = await axios.get(`${otherUrl}/veh/fetchContributionScheduleReport.action?schCustId=${id}`);
    return res?.data?.data;
});

export const fetchChamaInviteRequests = createAsyncThunk('fetchSlice/fetchChamaInviteRequests', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/invite/myInvite`);
    return res?.data?.data;
});


export const fetchSlice = createSlice({
    name: 'fetch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchChamaInviteRequests.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChamaInviteRequests.fulfilled, (state, action) => {
                state.loading = false;
                state.chamaInviteRequests = action.payload?.result;
            })
            .addCase(fetchChamaInviteRequests.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchPivot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPivot.fulfilled, (state, action) => {
                state.loading = false;
                state.pivotData = action.payload?.data;
            })
            .addCase(fetchPivot.rejected, (state) => {
                state.loading = false;
                state.pivotData = [];
            })

            .addCase(fetchContributionTypeMembers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchContributionTypeMembers.fulfilled, (state, action) => {
                state.loading = false;
                state.contributionTypeMembers = action.payload?.result;
            })
            .addCase(fetchContributionTypeMembers.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchChamaContributions.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChamaContributions.fulfilled, (state, action) => {
                state.loading = false;
                state.chamaContributions = action.payload?.result;
            })
            .addCase(fetchChamaContributions.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload?.result;
            })
            .addCase(fetchNotifications.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchDeclinedInvites.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDeclinedInvites.fulfilled, (state, action) => {
                state.loading = false;
                state.invitesRejected = action.payload?.result;
            })
            .addCase(fetchDeclinedInvites.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchPendingInvites.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPendingInvites.fulfilled, (state, action) => {
                state.loading = false;
                state.invitePendingApprovals = action.payload?.result;
            })
            .addCase(fetchPendingInvites.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchUserById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.loading = false;
                state.newUserByIdObj = action.payload?.result;
            })
            .addCase(fetchUserById.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchChamaGroupByCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChamaGroupByCode.fulfilled, (state, action) => {
                state.loading = false;
                state.chamaGroupObj = action.payload?.result;
            })
            .addCase(fetchChamaGroupByCode.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchGroupMembers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGroupMembers.fulfilled, (state, action) => {
                state.loading = false;
                state.members = action.payload?.result;
            })
            .addCase(fetchGroupMembers.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchSettlementAccounts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSettlementAccounts.fulfilled, (state, action) => {
                state.loading = false;
                state.settlementAccounts = action.payload?.result;
            })
            .addCase(fetchSettlementAccounts.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchInvitedmembers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchInvitedmembers.fulfilled, (state, action) => {
                state.loading = false;
                state.invitedMembers = action.payload?.result;
            })
            .addCase(fetchInvitedmembers.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchChamaRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChamaRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.roles = action.payload?.result;
            })
            .addCase(fetchChamaRoles.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchStatements.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStatements.fulfilled, (state, action) => {
                state.loading = false;
                state.statements = action.payload?.result;
            })
            .addCase(fetchStatements.rejected, (state) => {
                state.loading = false;
            })

            .addCase(mpesaPush.pending, (state) => {
                state.loading = true;
            })
            .addCase(mpesaPush.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(mpesaPush.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchChamas.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChamas.fulfilled, (state, action) => {
                state.loading = false;
                state.chamas = action.payload;
            })
            .addCase(fetchChamas.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
