import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleUpdateUser, updateOnboarding } from '../../features/auth/authSlice';
import { Input } from 'antd';
import { useState } from 'react';
import ReactPasswordChecklist from 'react-password-checklist';
import { CheckCircleOutlined } from '@ant-design/icons';
import checkBox from '../../assets/svg/Checkbox.svg';
import { customToast } from '../../utils';

export default function OnboardingOptions() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [password, setpassword] = useState('');
    const [isValid, setisValid] = useState(false);

    async function handleNext() {
        const res = await dispatch(
            updateOnboarding({
                secret: password,
            })
        );        

        if (res?.payload?.success) {
            await dispatch(
                handleUpdateUser({
                    changePassword: true,
                })
            );
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 111111111111111,
            });

            await navigate('/dashboard');
        } else {
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'error',
                id: 222222222222,
            });
        }
    }

    return (
        <>
            <div className="onboarding">
                <span className="heading_1">Change your password to continue</span>
                <span className="paragraph_1 mt-[1rem]">Lets start by enhancing your security</span>

                <div className="w-full flex justify-center items-center mt-[3rem]">
                    <div className="w-full lg:w-[400px]">
                        <Input.Password onChange={(e) => setpassword(e.target.value)} className="input" />

                        <div className="mt-[1.25rem]">
                            <span className="paragraph_3 !text-[#616161]">Password must contain:</span>
                            <ReactPasswordChecklist
                                className="text-darkBlue"
                                rules={['minLength', 'capital', 'number']}
                                minLength={8}
                                value={password}
                                onChange={(isValid) => {
                                    setisValid(isValid);
                                }}
                                messages={{
                                    minLength: 'A minimum of 8 characters',
                                    capital: 'At least one upper case letter (A-Z)',
                                    number: 'At least one number (0-9)',
                                }}
                                iconComponents={{
                                    ValidIcon: <CheckCircleOutlined className="text-[1rem] mr-[.5rem] text-[green]" />,
                                    InvalidIcon: <img className="mr-[.5rem]" src={checkBox} alt="checkBox" />,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-[10.625rem] mt-[5rem] flex flex-col gap-[.75rem]">
                    {isValid ? (
                        <button onClick={handleNext} className="cstm-btn-3">
                            Next
                        </button>
                    ) : (
                        <button disabled className="cstm-btn-3-disabled">
                            Next
                        </button>
                    )}

                    <button onClick={() => navigate('/onboarding')} className="cstm-btn">
                        Previous
                    </button>
                </div>
            </div>
        </>
    );
}
