import { useEffect, useMemo, useState } from 'react';
import UserBanner from '../../components/UserBanner';
import { useDispatch, useSelector } from 'react-redux';
import useFetchDispatch from '../../custom_hooks/useFetchDispatch';
import { fetchChamas } from '../../features/fetch/fetchSlice';
import Search from '../../components/Search';
import CardLoading from '../../components/CardLoading';
import useDynamicFilter from '../../custom_hooks/useDynamicFilter';
import ChamasCard from './cards/ChamasCard';
import { clearObj } from '../../features/obj/objSlice';
import { fetchChamaToken, handlememberRole } from '../../features/auth/authSlice';

export default function Chamas() {
    const dispatch = useDispatch();

    const { loading, chamas } = useSelector((state) => state.fetch);

    const [searchInput, setsearchInput] = useState('');

    const filterFns = useMemo(() => [(item) => String(item?.custName).toUpperCase().includes(String(searchInput).toUpperCase())], [searchInput]);

    const filteredData = useDynamicFilter(chamas, filterFns);

    const handleSearch = (val) => {
        setsearchInput(val);
    };

    async function handleInit() {
        await dispatch(fetchChamaToken(null));
        await dispatch(fetchChamas())
        await dispatch(clearObj());
        await dispatch(handlememberRole(''))
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handleInit()
    }, []);

    useEffect(() => {}, [searchInput, chamas]);

    if (loading) return <CardLoading />;

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] w-full">
                <UserBanner />

                <div className="flex flex-col w-full bg-white rounded-[1.25rem] h-full p-[2.5rem]">
                    {chamas?.length ? (
                        <>
                            <div className="w-[25rem]">
                                <Search readOnly={false} searchInput={searchInput} handleSearch={handleSearch} text={'Search...'} />
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 items-center h-auto w-full mt-[1.25rem] gap-[.44rem]">
                                {filteredData?.length ? (
                                    filteredData?.map((item, index) => {
                                        return (
                                            <div className="h-full" key={index}>
                                                <ChamasCard item={item} />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <span className="mt-[1.25rem] label_2">No record found</span>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="w-full h-full flex flex-col justify-center items-center py-[2.5rem]">
                                <span className="heading_5 my-[1.25rem]">There are no chamas created</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
