import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useFetchDispatch(func, variable = null) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            if (variable !== null) {
                await dispatch(func(variable));
            } else {
                await dispatch(func());
            }

            setIsLoading(false);
        };

        fetchData();
    }, [func, variable]);

    return { isLoading };
}
