import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    moneying: false,
    schedules: [],
    memberSchedules: [],
    statements: [],
    groupCardSummary: {},
    manualDebitNotes: [],
    scheduleHeaders: [],
    disbursements: [],
    reimbursements: [],
    settlements: [],
    myActivitySummary: [],
    accountTransfers: [],
    ndovuRates: {},
    withdrawalRates: {},
};

export const fetchMemberSchedules = createAsyncThunk('moneySlice/fetchMemberSchedules', async (memberNo) => {
    const res = await axiosInstance.get(`${url}/api/v1/schedule/mine?memberNo=${memberNo}`).then((res) => res.data.data.result);
    return res;
});

export const mpesaPushSchedule = createAsyncThunk('moneySlice/mpesaPushSchedule', async (data) => {
    const res = await axiosInstance.get(`${url}/api/v1/stk/${data?.id}?phone=${data?.phone}`);
    return res.data;
});

export const mpesaPushCustom = createAsyncThunk('moneySlice/mpesaPushCustom', async (data) => {
    const res = await axiosInstance.get(`${url}/api/v1/stk/main?phone=${data?.phone}&amount=${data?.amount}&accountNo=${data?.accountNo}`);
    return res.data;
});

export const fetchGroupCardsSummary = createAsyncThunk('moneySlice/fetchGroupCardsSummary', async (custId) => {
    const res = await axiosInstance.get(`${url}/api/v1/contr/summary`).then((res) => res.data.data.result);
    return res;
});

export const fetchGroupGraph = createAsyncThunk('moneySlice/fetchGroupGraph', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/schedule/chart`).then((res) => res.data.data.result);
    return res;
});

export const fetchManualDebitNotes = createAsyncThunk('moneySlice/fetchManualDebitNotes', async (id) => {
    const res = await axiosInstance.get(`${url}/api/v1/schedule/manual`);
    return res.data.data;
});

export const fetchMemberSchedulesHeaders = createAsyncThunk('moneySlice/fetchMemberSchedulesHeaders', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/header`).then((res) => res.data.data);
    return res;
});

export const fetchDisbursements = createAsyncThunk('moneySlice/fetchDisbursements', async () => {
    const res = await axiosInstance.get(`${url}/api/withdrawals/dynamic-withdraws?withDrawCategory=DISBURSEMENT`).then((res) => res.data.data);
    return res;
});

export const fetchReimbursements = createAsyncThunk('moneySlice/fetchReimbursements', async () => {
    const res = await axiosInstance.get(`${url}/api/withdrawals/dynamic-withdraws?withDrawCategory=REIMBURSEMENT`).then((res) => res.data.data);
    return res;
});

export const fetchSettlements = createAsyncThunk('moneySlice/fetchSettlements', async () => {
    const res = await axiosInstance.get(`${url}/api/withdrawals/dynamic-withdraws?withDrawCategory=SETTLEMENT`).then((res) => res.data.data);
    return res;
});

export const fetchAccountTransfers = createAsyncThunk('moneySlice/fetchAccountTransfers', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/walletTransfer`).then((res) => res.data.data);
    return res;
});

export const fetchMyActivitySummary = createAsyncThunk('moneySlice/fetchMyActivitySummary', async (memberNo) => {
    const res = await axiosInstance.get(`${url}/api/v1/schedule/summary?memberNo=${memberNo}`);
    return res?.data?.data;
});

export const fetchRates = createAsyncThunk('moneySlice/fetchRates', async (amount) => {
    const res = await axiosInstance.get(`${url}/tariff?amount=${amount}`);
    return res?.data?.data;
});

export const fetchWithdrawalRates = createAsyncThunk('moneySlice/fetchWithdrawalRates', async (amount) => {
    const res = await axiosInstance.get(`${url}/tariff/B2C?amount=${amount}`);
    return res?.data?.data;
});

export const moneySlice = createSlice({
    name: 'money',
    initialState,
    reducers: {
        handleResetrates: (state) => {
            state.withdrawalRates = {};
            state.ndovuRates = {};
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchWithdrawalRates.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchWithdrawalRates.fulfilled, (state, action) => {
                state.moneying = false;
                state.withdrawalRates = action.payload?.result;
            })
            .addCase(fetchWithdrawalRates.rejected, (state) => {
                state.moneying = false;
                state.withdrawalRates = {}
            })

            .addCase(fetchRates.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchRates.fulfilled, (state, action) => {
                state.moneying = false;
                state.ndovuRates = action.payload?.result;
            })
            .addCase(fetchRates.rejected, (state) => {
                state.moneying = false;
                state.ndovuRates = {}
            })

            .addCase(fetchAccountTransfers.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchAccountTransfers.fulfilled, (state, action) => {
                state.moneying = false;
                state.accountTransfers = action.payload?.result;
            })
            .addCase(fetchAccountTransfers.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchMyActivitySummary.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchMyActivitySummary.fulfilled, (state, action) => {
                state.moneying = false;
                state.myActivitySummary = action.payload?.result;
            })
            .addCase(fetchMyActivitySummary.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchSettlements.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchSettlements.fulfilled, (state, action) => {
                state.moneying = false;
                state.settlements = action.payload?.result;
            })
            .addCase(fetchSettlements.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchReimbursements.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchReimbursements.fulfilled, (state, action) => {
                state.moneying = false;
                state.reimbursements = action.payload?.result;
            })
            .addCase(fetchReimbursements.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchDisbursements.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchDisbursements.fulfilled, (state, action) => {
                state.moneying = false;
                state.disbursements = action.payload?.result;
            })
            .addCase(fetchDisbursements.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchMemberSchedulesHeaders.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchMemberSchedulesHeaders.fulfilled, (state, action) => {
                state.moneying = false;
                state.scheduleHeaders = action.payload?.result;
            })
            .addCase(fetchMemberSchedulesHeaders.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchManualDebitNotes.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchManualDebitNotes.fulfilled, (state, action) => {
                state.moneying = false;
                state.manualDebitNotes = action.payload?.result;
            })
            .addCase(fetchManualDebitNotes.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchGroupCardsSummary.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchGroupCardsSummary.fulfilled, (state, action) => {
                state.moneying = false;
                state.groupCardSummary = action.payload;
            })
            .addCase(fetchGroupCardsSummary.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(mpesaPushSchedule.pending, (state) => {
                state.moneying = true;
            })
            .addCase(mpesaPushSchedule.fulfilled, (state, action) => {
                state.moneying = false;
            })
            .addCase(mpesaPushSchedule.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(mpesaPushCustom.pending, (state) => {
                state.moneying = true;
            })
            .addCase(mpesaPushCustom.fulfilled, (state, action) => {
                state.moneying = false;
            })
            .addCase(mpesaPushCustom.rejected, (state) => {
                state.moneying = false;
            })

            .addCase(fetchMemberSchedules.pending, (state) => {
                state.moneying = true;
            })
            .addCase(fetchMemberSchedules.fulfilled, (state, action) => {
                state.moneying = false;
                state.memberSchedules = action.payload;
            })
            .addCase(fetchMemberSchedules.rejected, (state) => {
                state.moneying = false;
            });
    },
});

export default moneySlice.reducer;
export const { handleResetrates } = moneySlice.actions;
