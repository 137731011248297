import { Input } from 'antd';

export default function Search({ text, searchInput, handleSearch, readOnly=true }) {
	return (
		<>
			<div className='search'>
				<Input
					readOnly={readOnly}
					onChange={(e) => handleSearch(e.target.value)}
					value={searchInput}
					placeholder={text}
					className='input-borderless'
					type='text'
				/>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'>
					<path
						d='M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z'
						stroke='#212121'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M21.0703 21.0713L27.9991 28'
						stroke='#212121'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
		</>
	);
}
