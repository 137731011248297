import { Badge, Drawer, Dropdown, FloatButton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { clearObj } from '../features/obj/objSlice';
import { logout } from '../features/auth/authSlice';
import image10 from '../assets/svg/ndovuLogoNew.svg';
import userImg from '../assets/img/user.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { applicationUrl, capitalize, getRandomColor, openNotificationWithIcon } from '../utils';
import moment from 'moment/moment';
import { fetchNotifications } from '../features/fetch/fetchSlice';
import { deleteEntry, update } from '../features/save/saveSlice';
import SideBarOpen from './SideBarOpen';
import { toggleCollapse } from '../features/setup/setupSlice';

export default function SubHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { user } = useSelector((state) => state.auth);
    const { notifications } = useSelector((state) => state.fetch);
    const { collapsed } = useSelector((state) => state.setup);

    const [open, setopen] = useState(false);
    const [open1, setopen1] = useState(false);

    const [notificationArray, setnotificationArray] = useState(notifications);

    function handleToggle() {
        dispatch(toggleCollapse());
    }

    function handleOpenChange() {
        setopen(!open);
    }

    function handleOpenChange1() {
        setopen1(!open1);
    }

    async function handleDelete(item) {
        let data = {
            url: applicationUrl().url,
            saveUrl: `/api/v1/notification/${item}`,
        };

        let filterArray = notificationArray?.filter((x) => x?.notId !== item);

        await setnotificationArray(filterArray);
        await dispatch(deleteEntry(data));
        await dispatch(fetchNotifications(user?.usrId));
    }

    async function handleReadNotification(item) {
        let unread = await { ...item, notStatus: 'READ' };

        let updateObj = {
            url: applicationUrl().url,
            saveUrl: `/api/v1/notification/update/${item?.notId}`,
            data: unread,
        };
        await dispatch(update(updateObj));

        await dispatch(fetchNotifications(user?.usrId));
    }

    async function handleNotifications() {
        let unread = await notificationArray?.filter((item) => String(item?.notStatus).toUpperCase() === String('UNREAD').toUpperCase());
        await unread?.forEach((item) => {
            openNotificationWithIcon({
                key: item?.notId,
                type: 'info',
                title: <span className="text-[18px] font-bold">Notification</span>,
                content: <span className="text-[16px] font-medium">{item?.notBody}</span>,
                route: item?.ntfnDesc,
            });

            let notObj = {
                ...item,
                notStatus: 'RECEIVED',
            };

            let updateObj = {
                url: applicationUrl().url,
                saveUrl: `/api/v1/notification/update/${item?.notId}`,
                data: notObj,
            };
            dispatch(update(updateObj));
        });
    }

    async function handleLogout() {
        await dispatch(clearObj());
        await dispatch(logout());
        await localStorage.clear();
        await navigate('/login');
    }

    const profileDropDownItems = [
        {
            label: (
                <>
                    <div className="w-[20rem] mb-[2rem]">
                        <div className="w-full mt-[.5rem] flex flex-col px-[.75rem] py-[1rem] gap-y-[.5rem] rounded-[1rem]">
                            <span className="heading_4 !text-[#1E191B]">{capitalize(user?.userName)}</span>

                            <span className="font-semibold">{user?.email}</span>
                            <span>+{user?.phone}</span>

                            <div className="w-full flex flex-col items-center justify-center mt-[3rem]">
                                <div className="w-[9rem]">
                                    <button onClick={handleLogout} className="decline_btn">
                                        <LogoutOutlined className="text-[25px]" />
                                        <span className="ml-[.5rem]">Logout</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),
            key: '0',
        },
    ];

    const notificationDropDownItems =
        notificationArray?.length === 0
            ? [
                  {
                      key: '1',
                      label: <span className="text-[18px] w-full font-semibold p-5">No notification available </span>,
                  },
              ]
            : notificationArray?.map((item, index) => {
                  return {
                      key: item?.notId,
                      label: (
                          <div key={index}>
                              <div className={`flex items-start w-full min-h-[50px] p-3 ${item?.notStatus === 'UNREAD' || item?.notStatus === 'RECEIVED' ? 'bg-gray-200' : ''}`}>
                                  <div className="flex items-start w-full gap-[.5rem]">
                                      <img
                                          loading="lazy"
                                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.notCustName)}&background=${getRandomColor()}&color=fff`}
                                          alt="profile"
                                          class="w-12 h-12 rounded-full"
                                      />

                                      <div className="flex flex-col w-full ">
                                          <span className="text-[16px] font-bold text-black14">{item?.notCustName ? capitalize(item?.notCustName) : 'Chama Notification'}</span>

                                          <span className="w-full">{item?.notBody}</span>
                                          <span className="text-[14px] font-medium text-gray-500 mt-[.5rem]">{moment(item?.lastModifiedDate).startOf('hour').fromNow()}</span>

                                          <div className="flex items-center w-full mt-[.75rem]">
                                              {item?.notStatus === 'UNREAD' || item?.notStatus === 'RECEIVED' ? (
                                                  <button onClick={() => handleReadNotification(item)} className="w-fit text-[#4166C0] mr-5 rounded-[8px]">
                                                      Mark as read
                                                  </button>
                                              ) : null}

                                              <button onClick={() => handleDelete(item?.notId)} className="w-fit text-[#C8001B] rounded-[8px]">
                                                  Remove
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ),
                  };
              });

    useEffect(() => {
        handleNotifications();
        setnotificationArray(notifications);
    }, [pathname, notifications]);

    useEffect(() => {}, [collapsed, notificationArray]);

    return (
        <>
            <div className="w-full bg-white flex items-center justify-between px-[.75rem] py-[.5rem] lg:px-[2.12rem] lg:py-[1rem]">
                <div className="flex items-center">
                    <Link to="/dashboard" className="flex items-center w-full gap-[.5rem]">
                        <FloatButton
                            className="flex lg:hidden"
                            icon={
                                collapsed ? (
                                    <MenuFoldOutlined tooltip={<div className="label_2">Menu</div>} className="text-[1.35rem] text-[#FFCD47]" />
                                ) : (
                                    <MenuUnfoldOutlined tooltip={<div className="label_2">Menu</div>} className="text-[1.35rem] text-[#FFCD47]" />
                                )
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                handleToggle();
                            }}
                        />
                        <img src={image10} className="logo !w-fit h-full" alt="image2" />
                        <span className="heading_4 hidden lg:flex">Chamify Africa.</span>
                    </Link>
                </div>

                <div className="flex items-center gap-[1.5rem]">
                    <button className="mr-0 lg:mr-5 flex items-center">
                        <Dropdown
                            overlayClassName="avatar_dropdown"
                            onOpenChange={handleOpenChange1}
                            arrow
                            open={open1}
                            menu={{
                                items: notificationDropDownItems,
                            }}
                            trigger={['click']}
                            placement="bottom"
                        >
                            <div className="flex items-center">
                                <Badge count={notificationArray?.length} overflowCount={99}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path
                                            d="M22.2213 18.3758L21.3333 17.5812V10.6665C21.3333 9.25202 20.7714 7.89546 19.7712 6.89527C18.771 5.89507 17.4145 5.33317 16 5.33317C14.5855 5.33317 13.229 5.89507 12.2288 6.89527C11.2286 7.89546 10.6667 9.25202 10.6667 10.6665V17.5812L9.77867 18.3758C8.49007 19.5258 7.5465 21.0113 7.05334 22.6665H24.9467C24.4535 21.0113 23.5099 19.5258 22.2213 18.3758ZM20 25.3332C20 26.394 19.5786 27.4115 18.8284 28.1616C18.0783 28.9117 17.0609 29.3332 16 29.3332C14.9391 29.3332 13.9217 28.9117 13.1716 28.1616C12.4214 27.4115 12 26.394 12 25.3332H4.00001C3.99819 23.6446 4.35366 21.9747 5.04305 20.4332C5.73244 18.8917 6.74016 17.5135 8.00001 16.3892V10.6665C8.00001 8.54477 8.84286 6.50994 10.3432 5.00965C11.8434 3.50936 13.8783 2.6665 16 2.6665C18.1217 2.6665 20.1566 3.50936 21.6569 5.00965C23.1572 6.50994 24 8.54477 24 10.6665V16.3892C25.2599 17.5135 26.2676 18.8917 26.957 20.4332C27.6464 21.9747 28.0018 23.6446 28 25.3332H20ZM16 26.6665C16.3536 26.6665 16.6928 26.526 16.9428 26.276C17.1929 26.0259 17.3333 25.6868 17.3333 25.3332H14.6667C14.6667 25.6868 14.8071 26.0259 15.0572 26.276C15.3072 26.526 15.6464 26.6665 16 26.6665Z"
                                            fill="#121212"
                                        />
                                    </svg>
                                </Badge>
                            </div>
                        </Dropdown>
                        <span className="ml-[.75rem] text-[#1E191B] font-semibold">
                            {user?.usrFirstName} {user?.usrLastName}
                        </span>
                    </button>

                    <button className="mr-0 lg:mr-5 flex items-center">
                        <Dropdown
                            overlayClassName="avatar_dropdown"
                            onOpenChange={handleOpenChange}
                            arrow
                            open={open}
                            menu={{
                                items: profileDropDownItems,
                            }}
                            trigger={['click']}
                            placement="bottom"
                        >
                            <div className="flex items-center">
                                <div className="flex justify-center items-center p-[.4rem]">
                                    <img src={userImg} alt="user" className="w-[2.5rem] h-[2.5rem] object-cover rounded-full" />
                                </div>
                            </div>
                        </Dropdown>
                        <span className="ml-[.75rem] text-[#1E191B] font-semibold">
                            {user?.usrFirstName} {user?.usrLastName}
                        </span>
                    </button>
                </div>
            </div>

            <Drawer width={'fit-content'} title="Basic Drawer" placement={'left'} closable onClose={handleToggle} open={collapsed} key={'left'}>
                <SideBarOpen isMobile={Number(window.innerWidth) < 1024} />
            </Drawer>
        </>
    );
}
