import { useSelector } from 'react-redux';
import moment from 'moment';
import { getRandomColor } from '../../../utils';
import { useEffect } from 'react';

export default function MemberInfoCard() {
    const { activeViewOnlyMember } = useSelector((state) => state.obj);

    useEffect(() => {}, [activeViewOnlyMember]);

    return (
        <>
            <div className="flex items-start gap-[1.25rem] min-h-[16.75rem] h-auto bg-[#F2F4F7] px-[1.5rem] py-[2.75rem] w-full">
                <div className="hidden lg:flex w-[9.125rem] h-[9.125rem]">
                    <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(activeViewOnlyMember?.gpmName)}&background=${getRandomColor()}&color=fff`}
                        alt="avatar"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className="flex flex-col h-full">
                    <span className="heading_1">{activeViewOnlyMember?.gpmName}</span>
                    <span className="label_3 !text-[#64748B] my-[.25rem]">({activeViewOnlyMember?.gpmRoleName})</span>
                    <span className="label_3 !text-[#64748B]">Member since {moment(activeViewOnlyMember?.createdDate).format('Do MMM YYYY')}</span>

                    <div className="flex items-center mt-[1.25rem] w-full">
                        <span className="member_info_text w-[6rem]">ID No</span>
                        <span className="member_info_text !font-normal">{activeViewOnlyMember?.user?.usrNationalId ?? ' - '}</span>
                    </div>

                    <div className="flex items-center my-[.5rem] w-full">
                        <span className="member_info_text w-[6rem]">Phone No</span>
                        <span className="member_info_text !font-normal">{activeViewOnlyMember?.user?.usrMobileNumber ?? ' - '}</span>
                    </div>

                    <div className="flex items-center w-full">
                        <span className="member_info_text w-[6rem]">Email</span>
                        <span className="member_info_text !font-normal">{activeViewOnlyMember?.user?.email ?? ' - '}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
