import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import AddMemberSUbStepTable from './sub_steps/AddMemberSubStepTable';
import SendInviteModal from '../modals/SendInviteModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvitedmembers } from '../../../features/fetch/fetchSlice';

export default function AddMemberStep({ handleNext, form, handleBack }) {
    const dispatch = useDispatch();

    const { invitedMembers } = useSelector((state) => state.fetch);

    const [add, setadd] = useState(false);
    const [open, setopen] = useState(false);
    const [newInvites, setnewInvites] = useState([]);

    function getMemberInvites(list) {
        setnewInvites(list);
    }

    async function handleFetch() {
        await dispatch(fetchInvitedmembers());
    }

    async function handleSaveRole() {
        setopen(true);
    }

    function handleCancel() {
        setopen(false);
    }

    async function handleAddInitialMember() {
        await setadd(true);
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handleFetch();
    }, []);

    useEffect(() => {}, [invitedMembers]);

    return (
        <>
            <div className="flex flex-col w-full">
                {add || invitedMembers?.length ? (
                    <>
                        <AddMemberSUbStepTable getMemberInvites={getMemberInvites} />

                        <div className="py-[2.44rem] w-full flex items-center">
                            <div className="w-full flex items-center flex-row-reverse justify-between gap-[.5rem]">
                                <div className="w-[10.625rem]">
                                    <button disabled={newInvites.length === 0} onClick={handleSaveRole} type="button" className="cstm-btn-2">
                                        {false ? <Spin /> : 'Send invites'}
                                    </button>
                                </div>

                                {invitedMembers?.length ? (
                                    <>
                                        <div className="flex items-center gap-[1.25rem]">
                                            <div className="w-[10.625rem]">
                                                <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                                    Previous
                                                </button>
                                            </div>
                                            <div className="w-[10.625rem]">
                                                <button onClick={() => handleNext()} type="button" className="decline_btn">
                                                    Skip
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="w-[10.625rem]">
                                        <button onClick={() => setadd(false)} type="button" className="cstm-btn">
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col w-full justify-center text-center">
                            <span className="label_2">Add members</span>
                            <span className="paragraph_2">
                                Let’s add members to your group. <br />
                                <span className="!italic">You will need their ID numbers for a seamless process.</span>
                            </span>

                            <div className="py-[2.37rem] w-full flex justify-center items-center">
                                <div className="w-fit flex flex-col gap-[.5rem]">
                                    <button
                                        // disabled={saving}
                                        onClick={handleAddInitialMember}
                                        type="button"
                                        className="cstm-btn-2"
                                    >
                                        Add
                                    </button>

                                    <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                        Previous
                                    </button>

                                    <button className="mt-[2.25rem]" onClick={() => handleNext()} type="button">
                                        <span className="label_2 ">
                                            You can also <span className="underline">skip</span> this step
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <SendInviteModal newInvites={newInvites} open={open} handleCancel={handleCancel} handleNext={handleNext} />
        </>
    );
}
