import { Descriptions, Spin, Table } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from '../../utils';
import dayjs from 'dayjs';

export default function ChamaReview({ handleEditChama }) {
    const { newChamaData } = useSelector((state) => state.obj);
    const { roles, invitedMembers, settlementAccounts, chamaContributions } = useSelector((state) => state.fetch);

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const groupDeatils = [
        {
            key: '1',
            label: 'Group name',
            children: newChamaData?.custName,
        },
        {
            key: '2',
            label: 'Group description',
            children: newChamaData?.custGroupDescription,
        },
        {
            key: '3',
            label: 'Group features',
            children: newChamaData?.custFeatures?.map((item, index) => <span key={index}>{capitalize(item?.csfFeature)},</span>),
        },
        {
            key: '4',
            label: 'Group mandate',
            children: <span>{newChamaData?.custGroupMandate} signatories for all outgoing transactions</span>,
        },
    ];

    const colors = ['#F9FCFF', '#F9FFFA', '#FDF9FF', '#CCE8ED'];

    const columns = [
        {
            title: 'Member’s ID Number',
            dataIndex: 'mbiNationalId',
        },
        {
            title: 'Name',
            dataIndex: 'mbiName',
        },
        {
            title: 'Email',
            dataIndex: 'mbiEmail',
        },
        {
            title: 'Phone',
            dataIndex: 'mbiPhone',
        },
        {
            title: 'Role',
            dataIndex: 'mbiRole',
            render: (item) => (
                <div className="flex items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#F6F6F6]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.2002" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{item}</span>
                </div>
            ),
        },
    ];

    const contributionColumns = [
        {
            title: 'Type of contribution',
            dataIndex: 'rdnType',
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'rdnAmount',
        },
        {
            title: 'Frequency',
            dataIndex: 'rdnFrequencyDescription',
        },
        {
            title: 'Contribution day',
            dataIndex: 'rdnContributionDay',
        },
        {
            title: 'Contribution start date',
            dataIndex: 'rdnStartDate',
            render: (item) => <span>{dayjs(item).format('Do MMM YYYY')}</span>,
        },
    ];

    return (
        <>
            <div className="flex w-full flex-col pt-[2.94rem] full_page">
                <div className="flex flex-col w-full justify-center items-center">
                    <span className="heading_1">Review</span>
                    <span className="paragraph_1 mt-[1.25rem]">Review your group details below </span>
                </div>

                <div className="w-full flex justify-center items-center flex-col mt-[3.5rem]">
                    <div
                        style={{
                            width: '60rem',
                        }}
                        className="flex flex-col h-auto"
                    >
                        <div className="w-full rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem] flex flex-col gap-[2.75rem]">
                            <Descriptions
                                colon={false}
                                column={1}
                                title={
                                    <div className="flex justify-between pb-[2.25rem]">
                                        <span className="heading_4">Group details</span>
                                        <button onClick={() => handleEditChama(0)} className="underline" type="button">
                                            Edit
                                        </button>
                                    </div>
                                }
                                items={groupDeatils}
                            />

                            <div className="max-w-full w-full overflow-x-auto">
                                <span className="ant-descriptions-item-label">Contributions</span>
                                <section className="mt-[1.5rem]">
                                    <Table
                                        className="editable_table"
                                        size={'large'}
                                        bordered={false}
                                        columns={contributionColumns}
                                        dataSource={chamaContributions}
                                        rowKey={'rdnId'}
                                        pagination={{
                                            defaultPageSize: 10,
                                            hideOnSinglePage: true,
                                            pageSizeOptions: [10, 20, 50, 100],
                                        }}
                                    />
                                </section>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            width: '60rem',
                        }}
                        className="flex flex-col  w-full gap-[.38rem] mt-[2.5rem] rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem]"
                    >
                        <div className="flex justify-between pb-[2.25rem] w-full">
                            <span className="heading_4">Roles</span>
                            <button onClick={() => handleEditChama(1)} className="underline" type="button">
                                Edit
                            </button>
                        </div>
                        {roles?.map((item, index) => {
                            let color = colors[index];
                            return (
                                <div
                                    key={index}
                                    style={{
                                        background: color,
                                    }}
                                    className="flex gap-[1.5rem] justify-between items-center w-[35rem] rounded-[0.5rem] h-[4.625rem] p-[0.75rem]"
                                >
                                    <div className="flex flex-col gap-[.25rem]">
                                        <span className="role_header">{item?.roleName}</span>
                                        <span className="role_text">All permissions</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div
                        style={{
                            width: '60rem',
                        }}
                        className="flex flex-col  w-full gap-[.38rem] mt-[2.5rem] rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem]"
                    >
                        <div className="flex justify-between pb-[2.25rem] w-full">
                            <span className="heading_4">Members</span>
                            <button onClick={() => handleEditChama(4)} className="underline" type="button">
                                Edit
                            </button>
                        </div>
                        <div className="max-w-full w-full overflow-x-auto">
                            <section>
                                <Table
                                    className="editable_table"
                                    size={'large'}
                                    bordered={false}
                                    columns={columns}
                                    dataSource={invitedMembers}
                                    rowKey={'mbiId'}
                                    pagination={{
                                        defaultPageSize: 10,
                                        hideOnSinglePage: true,
                                        pageSizeOptions: [10, 20, 50, 100],
                                    }}
                                />
                            </section>
                        </div>
                    </div>

                    <div
                        style={{
                            width: '60rem',
                        }}
                        className="flex flex-col  w-full gap-[.38rem] mt-[2.5rem] rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem]"
                    >
                        <div className="flex justify-between w-full">
                            <span className="heading_4">Settlement account</span>
                            <button onClick={() => handleEditChama(5)} className="underline" type="button">
                                Edit
                            </button>
                        </div>

                        <span className="title_medium_m3 mt-[1.25rem]">MPESA</span>

                        {settlementAccounts?.filter((x) => x?.staType === 'MPESA')?.length ? (
                            settlementAccounts
                                ?.filter((x) => x?.staType === 'MPESA')
                                ?.map((item, index) => {
                                    return (
                                        <div key={index} className="flex gap-[1.5rem] justify-between items-center w-[35rem] rounded-[0.5rem] h-[4.625rem] p-[0.75rem]">
                                            <div className="flex items-center gap-[1rem]">
                                                <button type="button" className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#CBD5E1] flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M3.75 13.5L9 18.75L21 6.75" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                                <span className="role_header">{item?.staAccountNumber}</span>
                                            </div>
                                        </div>
                                    );
                                })
                        ) : (
                            <span className="title_medium_m3">No mpesa account added</span>
                        )}

                        <span className="title_medium_m3 mt-[1.25rem]">BANK</span>

                        {settlementAccounts?.filter((x) => x?.staType === 'BANK')?.length ? (
                            settlementAccounts
                                ?.filter((x) => x?.staType === 'BANK')
                                ?.map((item, index) => {
                                    return (
                                        <div key={index} className="flex gap-[1.5rem] justify-between items-center w-[35rem] rounded-[0.5rem] h-[4.625rem] p-[0.75rem]">
                                            <div className="flex items-center gap-[1rem]">
                                                <button type="button" className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#CBD5E1] flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M3.75 13.5L9 18.75L21 6.75" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                                <div className="flex flex-col items-start">
                                                    <span className="role_header">
                                                        {item?.staAccountNumber} | {item?.staBankName} BANK
                                                    </span>
                                                    <span className="role_header">{item?.staName}</span>
                                                    {item?.staProof ? <span className="role_header underline">{item?.staProof?.split('_').pop()}</span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                        ) : (
                            <span className="title_medium_m3">No bank account added</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="py-[3.5rem] w-full flex justify-end items-center">
                <div className="w-auto flex items-center gap-[.5rem]">
                    <button onClick={() => handleEditChama(5)} type="button" className="cstm-btn !w-[9rem]">
                        Back
                    </button>

                    <button disabled type="submit" className="cstm-btn-2 !gap-[.25rem] !w-[13rem]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.54961 17.6501L4.22461 12.3251L5.27461 11.2501L9.54961 15.5251L18.7246 6.3501L19.7746 7.4251L9.54961 17.6501Z" fill="#FFCD47" />
                        </svg>
                        <span className="flex flex-shrink-0">{false ? <Spin /> : 'Create chama'}</span>
                    </button>
                </div>
            </div>
        </>
    );
}
