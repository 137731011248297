import moment from 'moment';
import { capitalize, formatFilePath, getRandomColor } from '../../../utils';

export default function ChamaViewCard({ item }) {
    return (
        <>
            <div className="flex items-start gap-[1.25rem] w-full h-[16.75rem] bg-[#F2F4F7] px-[1.5rem] py-[2.75rem]">
                <div className="hidden lg:flex lg:flex-shrink-0 w-[9.125rem] h-[9.125rem]">
                    <img
                        src={
                            item?.custProfileImage
                                ? formatFilePath(item?.custProfileImage)
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(item?.custName)}&background=${getRandomColor()}&color=fff`
                        }
                        alt="avatar"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className="flex flex-col w-full">
                    <span className="heading_1">{capitalize(item?.custName)}</span>
                    <span className="label_3 !text-[#64748B]">Created date {moment(item?.createdDate).format('Do MMM YYYY')}</span>

                    <div className="flex items-center mt-[1.25rem] w-full">
                        <span className="member_info_text w-[17rem]">Created by</span>
                        <span className="member_info_text w-full !font-normal">{item?.createdBy}</span>
                    </div>

                    <div className="flex items-center my-[.5rem] w-full">
                        <span className="member_info_text w-[17rem]">Chama Code</span>
                        <span className="member_info_text w-full !font-normal">{String(item?.custInviteCode).toUpperCase()}</span>
                    </div>

                    <div className="flex items-center w-full">
                        <span className="member_info_text w-[17rem]">Members</span>
                        <span className="member_info_text w-full !font-normal">{item?.custMemberCount}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
