import { useSelector } from 'react-redux';
import Search from '../../../components/Search';
import AvailableBalanceCard from '../../group/cards/AvailbaleBalanceCard';
import MemberRecentTransactionTable from '../tables/MemberRecentTransactionTable';
import { applicationUrl, capitalize, getFistName } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import CardLoading from '../../../components/CardLoading';
import moment from 'moment';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import EditMemberModal from '../modals/EditMemberModal';

export default function MemberOverview() {
    const { open, handleCancel, handleOpen } = useModalToggle();

    const { activeViewOnlyMember } = useSelector((state) => state.obj);

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/member/summary?memberNo=${activeViewOnlyMember?.gpmNumber}`);

    const balanceObj = [
        {
            header: 'Total Contributions',
            amount: data?.totalSaving,
            background: 'rgba(147, 205, 147, 0.22)',
        },
        {
            header: 'Last contribution',
            amount: data?.lastContributionInfo?.lastContributionAmount,
            hasFooter: true,
            footer: data?.lastContributionInfo?.lastContributionDate ? moment(data?.lastContributionInfo?.lastContributionDate).format('Do MMMM YYYY') : 'N/A',
            background: '#fff',
        },
        // {
        // 	header: 'Active loans',
        // 	amount: data?.activeLoan,
        // 	background: '#fff',
        // },
        {
            header: 'Arrears',
            amount: data?.totalArrears,
            background: '#fff',
        },
        {
            header: 'Penalties',
            amount: data?.penalty,
            background: 'rgba(255, 228, 230, 0.69)',
        },
    ];

    return (
        <>
            <div className="mt-[2.5rem] flex flex-col !pb-[5rem] p-[1.25rem] w-full">
                <div className="flex items-center justify-between w-full">
                    <span className="heading_4">Overview of <br className='flex xl:hidden'/> {capitalize(getFistName(activeViewOnlyMember?.gpmName) + `'s`)} activity</span>

                    <button onClick={handleOpen} type="button" className="action_btn">
                        <div className="flex flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M5.15039 19.0031H6.40039L15.6504 9.75313L14.4254 8.50313L5.15039 17.7781V19.0031ZM18.8504 8.65312L15.4754 5.30313L16.5754 4.20312C16.9254 3.86979 17.3547 3.70312 17.8634 3.70312C18.3714 3.70312 18.7921 3.86979 19.1254 4.20312L20.1504 5.22812C20.4337 5.52812 20.5837 5.88213 20.6004 6.29013C20.6171 6.69879 20.4754 7.04479 20.1754 7.32812L18.8504 8.65312ZM17.7754 9.75313L7.02539 20.5031H3.65039V17.1281L14.4004 6.37812L17.7754 9.75313ZM15.0254 9.12813L14.4254 8.50313L15.6504 9.75313L15.0254 9.12813Z"
                                    fill="#FFCD47"
                                />
                            </svg>
                        </div>
                        <span className="hidden lg:flex">Edit member</span>
                    </button>
                </div>

                {isLoading ? (
                    <CardLoading />
                ) : (
                    <>
                        <div className="mt-[1.25rem] w-full flex flex-wrap items-center overflow-x-auto overflow-y-hidden gap-[.75rem]">
                            {balanceObj.map((item, index) => {
                                return (
                                    <div className="flex flex-grow flex-shrink-0 min-w-[15rem] w-auto" key={index}>
                                        <AvailableBalanceCard
                                            header={item.header}
                                            footer={item.footer}
                                            hasFooter={item.hasFooter}
                                            amount={item.amount}
                                            background={item.background}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                <span className="heading_4 mt-[2.5rem]">{capitalize(getFistName(activeViewOnlyMember?.gpmName) + `'s`)} recent transactions</span>
                <div className="flex items-center gap-[1rem] w-full mt-[1.25rem]">
                    <div className="w-[24.1875rem] ">
                        <Search text={'Search ref no., activity or date'} />
                    </div>
                </div>

                <MemberRecentTransactionTable />
            </div>

			<EditMemberModal open={open} handleCancel={handleCancel} />
        </>
    );
}
