import { useDispatch, useSelector } from 'react-redux';
import { handleEditChamaData, setActiveChama } from '../../../features/obj/objSlice';
import { applicationUrl, customToast } from '../../../utils';
import { save } from '../../../features/save/saveSlice';
import { Form } from 'antd';
import { useRef } from 'react';
import ChamaGroupForm from './ChamaGroupForm';

export default function GeneralSettingsForm({ toggle }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { activeChama } = useSelector((state) => state.obj);

    const formRef = useRef(null);

    async function handleCancel(){
        await toggle()
    }

    async function onFinish(values) {
        let data = {
            ...values,
            custId: activeChama?.custId,
            url: applicationUrl().url,
            saveUrl: '/api/v1/customers',
        };        

        const res = await dispatch(save(data));

        if (res?.payload?.success) {
            await dispatch(setActiveChama(res?.payload?.data?.result))
            await customToast({
                content: 'Chama group details updated',
                bdColor: 'success',
            });
            await handleCancel()
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
            });
        }
    }

    return (
        <>
            <Form
                onValuesChange={(data) => {
                    dispatch(handleEditChamaData(data));
                }}
                layout="vertical"
                ref={formRef}
                name="registration"
                onFinish={onFinish}
                scrollToFirstError
                form={form}
                initialValues={{
                    ...activeChama,
                }}
                className="w-full lg:w-auto"
            >
                <div className="grid grid-cols-1 gap-[1.25rem] pb-[3rem]">
                    <div className="w-full flex flex-col mt-[3.5rem]">
                        <div className="flex flex-col h-auto">
                            <div className="w-full flex pb-[2.25rem]">
                                <div className="w-full flex flex-col gap-[.5rem]">
                                    <span className="label_1">Group details</span>
                                </div>
                            </div>
                            <ChamaGroupForm />
                        </div>
                        {/* 
                        <div className="flex flex-col w-full gap-[.38rem] mt-[1rem]">
                            <div className="flex justify-between pb-[.5rem] w-full">
                                <span className="label_1">Roles</span>
                            </div>

                            <ChamaRolesForm />
                        </div> */}
                    </div>
                </div>

                <div className="w-full flex justify-end">
                    <div className="w-fit flex items-center gap-[.88rem]">
                        <button onClick={handleCancel} className="modal_btn_light" type="button">
                            Cancel
                        </button>
                        <button disabled className="modal_btn_dark" type="submit">
                            Save
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
}
