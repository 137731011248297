import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubHeader from './layout/SubHeader';
import axiosInstance from './instance';
import { useEffect } from 'react';

export const OnboardingRoute = () => {
    const { isActive, user } = useSelector((state) => state.auth);

    const token = localStorage.getItem('token');

    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };

    useEffect(() => {
        axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }, [token, isActive]);

    useEffect(() => {}, [user]);

    if (user?.changePassword) {
        return <Navigate to="/dashboard" replace />;
    }

    if (!isActive || !token) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex flex-col w-full sticky top-0 z-[10]">
                    <SubHeader />
                </div>
                <div className="w-full h-full flex justify-center items-center">
                    <Outlet />
                </div>
            </div>
        </>
    );
};
