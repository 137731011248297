import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import ChamaViewCard from './cards/ChamaViewCard';
import { useNavigate } from 'react-router-dom';
import { handleNewChamaData, handleSupportViewType, setActiveMember } from '../../features/obj/objSlice';
import { fetchGroupMembers } from '../../features/fetch/fetchSlice';
import CardLoading from '../../components/CardLoading';
import TableLoading from '../../components/TableLoading';
import { capitalize, capitalizeFirstLetter, getFistName } from '../../utils';
import { handleOpenKeys, handleSelectedKeys } from '../../features/setup/setupSlice';
import { fetchChamaToken, handlememberRole } from '../../features/auth/authSlice';

export default function ChamaViewOption() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { activeChama } = useSelector((state) => state.obj);
    const { members, loading } = useSelector((state) => state.fetch);

    const [viewType, setviewType] = useState('');
    const [selectedMember, setselectedMember] = useState({});

    function handleChange(val) {
        setviewType(val);
        setselectedMember({});
    }

    async function handleNext() {
        await dispatch(handleSupportViewType(viewType));

        if (viewType === 'MEMBER') {
            await dispatch(setActiveMember(selectedMember));
            await dispatch(handlememberRole(selectedMember?.gpmRoleName));
            await dispatch(handleOpenKeys(['4']));
            await dispatch(handleSelectedKeys(['4']));
            return await navigate('/activity/overview');
        }

        return await navigate('/support/summary');
    }

    async function handleViewChama() {
        await dispatch(handleNewChamaData(activeChama));
        await navigate('/new/chama-group');
    }

    async function handleFetch() {
        const res = await dispatch(fetchChamaToken(activeChama?.custId));

        if (res?.payload?.success) {
            if (activeChama?.custStatus !== 'DRAFT') {
                await dispatch(fetchGroupMembers(activeChama?.custId));
            }
        }
    }

    useEffect(() => {
        handleFetch();
    }, [activeChama]);

    useEffect(() => {}, [members, selectedMember]);

    if (loading) {
        return (
            <div className="flex flex-col">
                <TableLoading />
                <CardLoading />
            </div>
        );
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] w-full pb-[5rem]">
                <div className="w-full flex justify-center items-center">
                    <div className="w-[60rem] flex flex-col gap-[2.5rem]">
                        <ChamaViewCard item={activeChama} />

                        {activeChama?.custStatus === 'DRAFT' ? (
                            <>
                                <div className="white_card flex flex-col rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem] min-h-[25rem] h-auto">
                                    <span className="label_2 mb-[1.25rem] mt-[2rem]">This chama group is still a DRAFT chama. Would you like to review?</span>
                                    <div className="w-full flex justify-center items-end">
                                        <div className="min-w-[10.625rem] w-auto mt-[5rem] flex flex-col gap-[.75rem]">
                                            <button onClick={handleViewChama} className="cstm-btn-3">
                                                Review chama
                                            </button>
                                            <button onClick={() => navigate(-1)} className="cstm-btn">
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="white_card flex flex-col rounded-[0.5rem] border border-[#E2E9EE] bg-white p-[2.5rem] min-h-[25rem] h-auto">
                                    <span className="label_2 mb-[1.25rem]">Choose how you would like to proceed to this chama</span>
                                    <Radio.Group onChange={(e) => handleChange(e.target.value)} value={viewType}>
                                        <Radio value={'OVERALL'}>Continue with overall view</Radio>
                                        <Radio value={'MEMBER'}>Continue as member</Radio>
                                    </Radio.Group>

                                    {viewType === 'MEMBER' ? (
                                        <div className="w-full flex flex-col gap-[.25rem] mt-[2.25rem]">
                                            <span className="label_2">Select a member</span>

                                            {members?.map((item, index) => {
                                                return (
                                                    <button onClick={() => setselectedMember(item)} type="button" className="w-[80%] pointer" key={index}>
                                                        <div className={`${selectedMember?.gpmId === item?.gpmId ? 'account_card' : 'account_card_alt'} w-full mt-[.69rem]`}>
                                                            <div className="w-full flex justify-between items-center pointer">
                                                                <div className="flex flex-col items-start">
                                                                    <span className="label_1">
                                                                        {capitalizeFirstLetter(item?.gpmName)} | {String(item?.user?.email).toLowerCase()} |{' '}
                                                                        {item?.user?.usrMobileNumber}{' '}
                                                                    </span>
                                                                    <span className="paragraph_1">{capitalize(item?.gpmRoleName)}</span>
                                                                </div>
                                                                {selectedMember?.gpmId === item?.gpmId ? (
                                                                    <div className="view_more_text">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <path
                                                                                d="M6 4L10 8L6 12"
                                                                                stroke="#121212"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    ) : null}

                                    <div className="flex-grow"></div>

                                    <div className="w-full flex justify-center items-end">
                                        <div className="min-w-[10.625rem] w-auto mt-[5rem] flex flex-col gap-[.75rem]">
                                            {viewType === 'MEMBER' ? (
                                                <>
                                                    {selectedMember?.gpmId ? (
                                                        <button onClick={handleNext} className="cstm-btn-3">
                                                            Continue as {capitalize(getFistName(selectedMember?.gpmName))}
                                                        </button>
                                                    ) : (
                                                        <button disabled className="cstm-btn-3-disabled">
                                                            Next
                                                        </button>
                                                    )}
                                                </>
                                            ) : viewType === 'OVERALL' ? (
                                                <>
                                                    <button onClick={handleNext} className="cstm-btn-3">
                                                        Continue
                                                    </button>
                                                </>
                                            ) : (
                                                <button disabled className="cstm-btn-3-disabled">
                                                    Next
                                                </button>
                                            )}
                                            <button onClick={() => navigate(-1)} className="cstm-btn">
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
