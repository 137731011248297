import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { applicationUrl, customToast, getRandomColor } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import {
	fetchDeclinedInvites,
	fetchPendingInvites,
} from '../../../features/fetch/fetchSlice';

export default function MembersPendingApprovalTable() {
	const { isLoading } = useFetchDispatch(fetchPendingInvites);

	const { invitePendingApprovals } = useSelector((state) => state.fetch);

	const { saving } = useSelector((state) => state.save);

	const dispatch = useDispatch();

	async function handleDecline(item) {
		let data = {};
		data.mbiInviteLink = item?.mbiInviteLink;
		data.mbiStatus = 'REJECT';
		data.url = applicationUrl().url;
		data.saveUrl = '/api/v1/reg/inviteAction';

		const res = await dispatch(save(data));

		if (res?.payload?.success) {
			await dispatch(fetchPendingInvites());
			await dispatch(fetchDeclinedInvites());
			await customToast({
				content: res?.payload?.messages?.message ?? 'Invite has been declined.',
				bdColor: 'success',
			});
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	async function handleAccept(item) {
		let data = {};
		data.mbiInviteLink = item?.mbiInviteLink;
		data.mbiStatus = 'ACCEPT';
		data.url = applicationUrl().url;
		data.saveUrl = '/api/v1/reg/inviteAction';

		const res = await dispatch(save(data));

		if (res?.payload?.success) {
			await dispatch(fetchPendingInvites());
			await customToast({
				content: res?.payload?.messages?.message ?? 'Invite has been accepted.',
				bdColor: 'success',
			});
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	useEffect(() => {}, [invitePendingApprovals]);

	if (isLoading) return <TableLoading />;

	const columns = [
		{
			title: 'Date',
			dataIndex: 'mbiInviteDate',
			render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
		},
		{
			title: 'Name',
			dataIndex: 'mbiName',
			render: (item) => (
				<>
					<div className='w-full flex items-center gap-[.5rem]'>
						<img
							src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
							className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
							alt='avatar'
						/>
						<span>{item}</span>
					</div>
				</>
			),
		},
		{
			title: 'National ID',
			dataIndex: 'mbiNationalId',
		},
		{
			title: 'Phone',
			dataIndex: 'mbiPhone',
		},
		{
			title: 'Email',
			dataIndex: 'mbiEmail',
		},
		{
			title: 'Action',
			render: (item) => (
				<div className='flex items-center gap-[.75rem]'>
					<button
						disabled
						// onClick={() => handleDecline(item)}
						className='modal_btn_light'
						type='button'>
						Reject
					</button>
					<button
						disabled
						// onClick={() => handleAccept(item)}
						className='modal_btn_dark'
						type='button'>
						Accept
					</button>
				</div>
			),
		},
	];
	return (
		<div>
			<Table
				rowKey='mbiId'
				className='mt-[1.5rem] !w-full'
				columns={columns}
				dataSource={invitePendingApprovals}
				scroll={{
					x: 1000
				}}
				pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
			/>
		</div>
	);
}
