import { useState, useEffect } from 'react';

export default function useDynamicFilter(data, filters) {
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        const applyFilters = (item) => {
            return filters?.every((filterFn) => filterFn(item));
        };

        setFilteredData(data?.filter(applyFilters));
    }, [data, filters]);

    return filteredData;
}
