import { useEffect, useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import { applicationUrl, customToast } from '../../../../utils';
import { handleNewChamaData } from '../../../../features/obj/objSlice';
import RemoveSettlementAccountModal from '../../modals/RemoveSettlementAccountModal';

export default function SettleMentAccountMpesaSubStep({ form, handleBack, handleFetch, handleReview }) {
    const dispatch = useDispatch();

    const { settlementAccounts } = useSelector((state) => state.fetch);
    const { newChamaData } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [add, setadd] = useState(false);
    const [showOtp, setshowOtp] = useState(false);
    const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
    const [open, setopen] = useState(false);
    const [accId, setaccId] = useState();
    const [phone, setphone] = useState('');
    const [deleteObj, setdeleteObj] = useState({});

    const cancatenatedString = verificationCode?.join('');

    function handleOpen(value) {
        setopen(true);
        setdeleteObj(value);
    }

    function handleCancel() {
        setopen(false);
        setdeleteObj({});
    }

    const handleInputChange = (e, index) => {
        const { value } = e.target;

        if (value === '') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);
    };

    async function handleSave() {
        try {
            await form.validateFields(['staAccountNumber', 'staName']);
            let data = form.getFieldsValue();
            data.staCustId = newChamaData?.custId;
            data.staType = 'MPESA';
            data.url = applicationUrl().url;
            data.saveUrl = '/api/v1/settlement';

            const res = await dispatch(save(data));

            if (res?.payload?.success) {
                await setaccId(res?.payload?.data?.result?.staId);
                await setadd(false);
                await setshowOtp(true);
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
            }
        } catch {}
    }

    async function handleVerifyCode() {
        try {
            let data = {
                secretId: accId,
                secret: cancatenatedString,
                url: applicationUrl().url,
                saveUrl: '/api/v1/settlement/verifyOTP',
            };

            const res = await dispatch(save(data));

            if (res?.payload?.success) {
                await setVerificationCode(['', '', '', '']);
                await inputRefs.forEach((item) => item?.current?.value === null);
                await setphone('');
                await dispatch(
                    handleNewChamaData({
                        staAccountNumber: null,
                        staName: null
                    })
                );
                await setaccId();
                await setshowOtp(false);
                await handleFetch();
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
                setVerificationCode(['', '', '', '']);
                inputRefs.forEach((item) => item?.current?.value === null);
            }
        } catch {}
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const colors = ['#F9FCFF', '#F9FFFA', '#FDF9FF'];

    return (
        <>
            <div className="flex flex-col w-full">
                {settlementAccounts?.length && !add && !showOtp ? (
                    <>
                        <div className="flex flex-col justify-center items-center w-full gap-[.38rem]">
                            {settlementAccounts
                                ?.filter((x) => x?.staType === 'MPESA')
                                ?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                background: colors[index],
                                            }}
                                            className="flex gap-[1.5rem] justify-between items-center w-[35rem] rounded-[0.5rem] h-[4.625rem] p-[0.75rem]"
                                        >
                                            <div className="flex flex-col gap-[.25rem]">
                                                <span className="role_header">{item?.staName} | {item?.staAccountNumber}</span>
                                            </div>
                                            <button type="button" onClick={() => handleOpen(item)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z"
                                                        fill="#343330"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    );
                                })}

                            <button type="button" onClick={() => setadd(true)} className="mt-[1.63rem] w-[35rem] flex items-center gap-[.5rem]">
                                <div className="black-round-btn">
                                    <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </div>

                                <span className="role_btn_text">Add number</span>
                            </button>
                        </div>

                        <div className="py-[2.31rem] mt-[5rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button onClick={handleReview} type="button" className="cstm-btn-2">
                                    Next
                                </button>

                                <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                    Previous
                                </button>
                            </div>
                        </div>
                    </>
                ) : add ? (
                    <>
                        <Form.Item
                            label="M-PESA Phone number"
                            name="staAccountNumber"
                            className="mt-[3rem]"
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <Input
                                value={phone}
                                onChange={(e) => setphone(e.target.value)}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                className="input"
                            />
                        </Form.Item>

                        <Form.Item
                            className="table-form-item"
                            label="Account name"
                            name="staName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <Input className="input" />
                        </Form.Item>

                        <div className="py-[2.44rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button disabled type="button" className="cstm-btn-2">
                                    {saving ? <Spin /> : 'Next'}
                                </button>

                                <button
                                    disabled={saving}
                                    onClick={() => {
                                        dispatch(
                                            handleNewChamaData({
                                                staAccountNumber: null,
                                                staName: null
                                            })
                                        );
                                        setadd(false);
                                    }}
                                    type="button"
                                    className="cstm-btn"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                ) : showOtp ? (
                    <>
                        <div className="flex flex-col justify-center items-center mt-[3.38rem] gap-[1.32rem]">
                            <span className="paragraph_3">Enter OTP sent to {phone}</span>
                            <div className="flex items-center gap-[1.25rem]">
                                {inputRefs?.map((ref, index) => (
                                    <div key={index}>
                                        <input
                                            ref={ref}
                                            className="otp-input"
                                            type="text"
                                            maxLength="1"
                                            value={verificationCode[index]}
                                            onChange={(e) => handleInputChange(e, index)}
                                            onKeyDown={(e) => {
                                                e.key === 'Enter' && e.preventDefault();
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="py-[2.44rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button disabled={saving} onClick={handleVerifyCode} type="button" className="cstm-btn-2">
                                    {saving ? <Spin /> : 'Verify'}
                                </button>

                                <button
                                    disabled={saving}
                                    onClick={() => {
                                        setadd(true);
                                        setshowOtp(false);
                                        dispatch(
                                            handleNewChamaData({
                                                staAccountNumber: null,
                                                staName: null
                                            })
                                        );
                                        setaccId();
                                        setVerificationCode(['', '', '', '']);
                                        inputRefs.forEach((item) => item?.current?.value === null);
                                    }}
                                    type="button"
                                    className="cstm-btn"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col w-full mt-[4rem] justify-center items-center">
                            <span className="settlement_empty_text">Phone numbers you add will be displayed here</span>

                            <div className="w-full flex justify-center items-center">
                                <button onClick={() => setadd(true)} className="mt-[2.5rem] flex items-center gap-[.5rem]">
                                    <div className="black-round-btn">
                                        <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </div>

                                    <span className="role_btn_text">Add number</span>
                                </button>
                            </div>

                            <div className="pb-[2.37rem] mt-[5rem] w-full flex justify-center items-center">
                                <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                    <button type="button" onClick={handleReview} className="cstm-btn-2">
                                        Skip
                                    </button>

                                    <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                        Previous
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <RemoveSettlementAccountModal handleFetch={handleFetch} deleteObj={deleteObj} open={open} handleCancel={handleCancel} />
        </>
    );
}
