import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, formatMoney } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import useFetch from '../../../custom_hooks/useFetch';
import { debitNoteTypeColor } from '../../../data';
import { useSelector } from 'react-redux';

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        sorter: (a, b) => a - b,
    },
    {
        title: 'Activity',
        dataIndex: 'trxSource',
        render: (item) => {
            const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_').join(' '))}</span>
                </div>
            );
        },
    },
    {
        title: 'Debit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'DEBIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Credit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'CREDIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    // {
    // 	title: 'Running balance (KES)',
    // 	dataIndex: 'trxRef',
    // 	render: (item) => <span>-</span>,
    // },
];

export default function ActivityRecentTransactionTable() {
    const { activeMember } = useSelector((state) => state.obj);

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/recent?memberNo=${activeMember?.gpmNumber}`);

    if (isLoading) return <TableLoading />;

    return (
        <>
            <Table
                rowKey="trxId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                scroll={{
                    x: 1200,
                }}
                columns={columns}
                dataSource={data?.content}
            />
        </>
    );
}
