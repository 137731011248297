import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, formatMoney, getRandomColor } from '../../../utils';
import { handleActiveTab, setActiveViewOnlyMember } from '../../../features/obj/objSlice';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';
import { useEffect } from 'react';

export default function MembersStatementSummaryTable() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/member`);

    const { members } = useSelector((state) => state.fetch);

    async function handleActiveMember(item) {
        let grpObj = members?.find((x) => x?.gpmNumber === item?.trxMemberNo || x?.gpmName === item?.trxMemberName);
        await dispatch(
            handleActiveTab({
                memberDetails: '2',
            })
        );
        await dispatch(setActiveViewOnlyMember(grpObj));
        await navigate(`/members/${grpObj?.gpmName}`);
    }

    useEffect(() => {}, [data?.content]);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Member name',
            dataIndex: 'trxMemberName',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Expected Amount',
            dataIndex: 'totalDebit',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Amount contributed',
            dataIndex: 'totalCredit',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Arrears',
            dataIndex: 'totalArrears',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Action',
            render: (item) => (
                <button onClick={() => handleActiveMember(item)} type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                        <path
                            d="M9.69922 2.23333C12.9724 2.23333 15.8915 4.07933 17.3165 7C15.8915 9.92067 12.981 11.7667 9.69922 11.7667C6.4174 11.7667 3.50695 9.92067 2.08195 7C3.50695 4.07933 6.42604 2.23333 9.69922 2.23333ZM9.69922 0.5C5.38104 0.5 1.69331 3.19533 0.199219 7C1.69331 10.8047 5.38104 13.5 9.69922 13.5C14.0174 13.5 17.7051 10.8047 19.1992 7C17.7051 3.19533 14.0174 0.5 9.69922 0.5ZM9.69922 4.83333C10.891 4.83333 11.8583 5.804 11.8583 7C11.8583 8.196 10.891 9.16667 9.69922 9.16667C8.5074 9.16667 7.54013 8.196 7.54013 7C7.54013 5.804 8.5074 4.83333 9.69922 4.83333ZM9.69922 3.1C7.5574 3.1 5.81286 4.85067 5.81286 7C5.81286 9.14933 7.5574 10.9 9.69922 10.9C11.841 10.9 13.5856 9.14933 13.5856 7C13.5856 4.85067 11.841 3.1 9.69922 3.1Z"
                            fill="#1D191B"
                        />
                    </svg>
                </button>
            ),
        },
    ];

    return (
        <>
            <Table
                rowKey="trxMemberName"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                scroll={{
                    x: 1200,
                }}
                columns={columns}
                dataSource={data?.content}
            />
        </>
    );
}
