import { useEffect } from 'react';
import UserBanner from '../../components/UserBanner';
import { useDispatch } from 'react-redux';
import { clearObj } from '../../features/obj/objSlice';
import { fetchChamaToken, handlememberRole } from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';

export default function MainHome() {
	const dispatch = useDispatch();

	async function handleInit() {
		await dispatch(clearObj());
		await dispatch(handlememberRole(''))
		await dispatch(fetchChamaToken(null));
	}

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
		handleInit();
	}, []);

	

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>
				<UserBanner />

				<span>Dashboard is under development</span>

				<div className='w-fit'>
				<Link to="/chamas" className='cstm-btn' type="button">View all chamas</Link>
				</div>
				{/* {data?.length ? (
					<PendingChamaList pendingInvites={data} />
				) : null}

				<ChamaList /> */}
			</div>
		</>
	);
}
